import React, { Suspense, lazy } from 'react';

const Loading = () => <div>Loading....</div>;
const AdminMain = lazy(() => import('../../pages/main/AdminMainPage'));
const AddPage = lazy(() => import('../../pages/main/AddPage'));
const ModifyPage = lazy(() => import('../../pages/main/ModifyPage'));

const AdminMainRouter = () => {
  return [
    {
      path: 'main',
      element: (
        <Suspense fallback={<Loading />}>
          <AdminMain />
        </Suspense>
      ),
      children: [
        {
          path: 'add',
          element: (
            <Suspense fallback={<Loading />}>
              <AddPage />
            </Suspense>
          ),
        },
        {
          path: 'modify/:date',
          element: (
            <Suspense fallback={<Loading />}>
              <ModifyPage />
            </Suspense>
          ),
        },
      ],
    },
  ];
};

export default AdminMainRouter;
