import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginPost } from "../api/memberApi";
import { getCookie, setCookie, removeCookie } from "../util/cookieUtil";

const initState = {
  m_email: "",
  isAdmin: false,
};

// 쿠키에서 회원 정보를 로드하는 함수
const loadMemberCookie = () => {
  const memberInfo = getCookie("member");
  if (memberInfo && memberInfo.m_name) {
    memberInfo.nickname = decodeURIComponent(memberInfo.m_name);
  }
  return {
    ...memberInfo,
    isAdmin: memberInfo && memberInfo.roleNames && memberInfo.roleNames.includes("ADMIN"),
  };
};

// 로그인 비동기 작업 생성
export const loginPostAsync = createAsyncThunk("loginPostAsync", async (param) => {
  const response = await loginPost(param);
  return response;
});

const loginSlice = createSlice({
  name: "LoginSlice",
  initialState: loadMemberCookie() || initState,
  reducers: {
    login: (state, action) => {
      console.log("로그인.....");
      const data = action.payload;
      return { m_email: data.m_email };
    },
    logout: (state) => {
      console.log("로그아웃....");
      removeCookie("member");
      return { ...initState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginPostAsync.fulfilled, (state, action) => {
        console.log("fulfilled : 완료");
        const payload = action.payload;
        if (!payload.error) {
          console.log("쿠키 저장");
          
          // HTTPS 환경에서만 secure 옵션을 설정
          const isSecure = window.location.protocol === "https:";

          setCookie("member", JSON.stringify(payload), {
            expires: 1, // 1일 동안 쿠키 유지 (날짜로 변환됨)
            path: "/",
            secure: isSecure,  // 환경에 따라 secure 설정
            sameSite: "strict", // CSRF 방지를 위한 sameSite 옵션
          });

          return { ...payload, isAdmin: payload.roleNames && payload.roleNames.includes("ADMIN") };
        }
        return state;
      })
      .addCase(loginPostAsync.pending, () => {
        console.log("pending : 처리중");
      })
      .addCase(loginPostAsync.rejected, (state, action) => {
        console.log("rejected : 오류", action.error);
        const error = action.error.message;
        if (error === "Unauthorized") {
          alert("이메일과 패스워드를 다시 확인하세요");
        } else {
          alert(`로그인 중 오류가 발생했습니다. 다시 시도해주세요. (오류: ${error})`);
        }
      });
  },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
