import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import MemberRouter from "../../member/router/member/MemberRouter";
import AdminMainRouter from "../../admin/router/main/AdminMainRouter";

const Loading = <div>Loading....</div>;
const Main = lazy(() => import("../../member/pages/main/MainPage"));
const AdminMain = lazy(() => import("../../admin/pages/main/AdminMainPage"));

const root = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={Loading}>
        <Main />
      </Suspense>
    ),
  },
  {
    path: "member",
    children: MemberRouter(),
  },
  {
    path: "admin",
    element: (
      <Suspense fallback={Loading}>
        <AdminMain />
      </Suspense>
    ),
    children: AdminMainRouter(),
  },
]);

export default root;
