import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (m_name, value, options = {}) => {
  const { expires, ...restOptions } = options;

  // expires 옵션이 숫자(일 수)로 전달되었을 경우 Date 객체로 변환
  let expiresDate;
  if (typeof expires === "number") {
    expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + expires);
  } else {
    expiresDate = expires;
  }

  return cookies.set(m_name, value, { ...restOptions, expires: expiresDate });
};

export const getCookie = (m_name) => {
  return cookies.get(m_name);
};

export const removeCookie = (m_name, path = "/") => {
  cookies.remove(m_name, { path });
};
