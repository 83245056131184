import { Suspense, lazy } from "react";

const Loading = <div>Loading....</div>;
const LoginPage = lazy(() => import("../../pages/member/LoginPage"));
const LogoutPage = lazy(() => import("../../pages/member/LogoutPage"));
const SignupPage = lazy(() => import("../../pages/member/SignupPage"));
const MyPage = lazy(() => import("../../pages/member/MyPage"));
const ForgetPasswordPage = lazy(() => import("../../pages/member/ForgetPasswordPage"));
const ResetPasswordPage = lazy(() => import("../../pages/member/ResetPasswordPage"));

const MemberRouter = () => {
  return [
    {
      path: "login",
      element: (
        <Suspense fallback={Loading}>
          <LoginPage />
        </Suspense>
      ),
    },
    {
      path: "logout",
      element: (
        <Suspense fallback={Loading}>
          <LogoutPage />
        </Suspense>
      ),
    },
    {
      path: "signup",
      element: (
        <Suspense fallback={Loading}>
          <SignupPage />
        </Suspense>
      ),
    },
    {
      path: "mypage",
      element: (
        <Suspense fallback={Loading}>
          <MyPage />
        </Suspense>
      ),
    },
    {
      path: "forgetpassword",
      element: (
        <Suspense fallback={Loading}>
          <ForgetPasswordPage />
        </Suspense>
      ),
    },
    {
      path: "resetpassword",
      element: (
        <Suspense fallback={Loading}>
          <ResetPasswordPage />
        </Suspense>
      ),
    }
  ];
};

export default MemberRouter;
