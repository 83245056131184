import axios from "axios";

const host = "https://dailyenglish.co.kr/api/member";

export const postAdd = async (member) => {
  const res = await axios.post(`${host}/`, member);
  return res.data;
};

export const getOne = async (m_num) => {
  const res = await axios.get(`${host}/${m_num}`);
  return res.data;
};

export const putOne = async (member) => {
  const res = await axios.put(`${host}/${member.m_num}`, member);
  return res.data;
};

export const loginPost = async (loginParam) => {
  const header = { headers: { "Content-Type": "x-www-form-urlencoded" } };

  const form = new FormData();
  form.append("username", loginParam.email);
  form.append("password", loginParam.pw);

  const res = await axios.post(`${host}/login`, form, header);

  return res.data;
};

export const getList = async (pageParam) => {
  const { page, size } = pageParam;
  const res = await axios.get(`${host}/list`, {
    params: { page: page, size: size },
  });
  return res.data;
};

export const getList2 = async (pageParam) => {
  const { page, size } = pageParam;
  const res = await axios.get(`${host}/retire`, {
    params: { page: page, size: size },
  });
  return res.data;
};
